<template>
    <Modal v-model="showForm" title="新增消息" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="90">
            <FormItem label="消息标题" prop="title">
                <Input v-model.trim="addForm.title" :maxlength="20" placeholder="请输入" clearable/>
            </FormItem>
            <FormItem label="消息内容" prop="content">
                <Input v-model.trim="addForm.content" :maxlength="500" :autosize="{minRows: 2,maxRows: 5}" show-word-limit type="textarea" placeholder="请输入" />
            </FormItem>
            <FormItem label="接收人" prop="departmentIds">
                <TreeSelect v-model="addForm.departmentIds" :options="departmentOpts" :multiple="true" placeholder="请选择" noOptionsText="暂无数据" noResultsText="没有匹配数据" :clear-on-select="clearOnSelect" clearable />
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {reqAddMessage, reqGetDepartmentOrgOpts} from "@/api/admin-api";
import TreeSelect from "@riophae/vue-treeselect";
export default {
    name: "addMessage",
    components: {
        TreeSelect
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            loading: false,
            departmentOpts: [],
            showForm: this.value,
            clearOnSelect: true,
            addForm: {
                title: '',
                content: '',
                departmentIds: null,
            },
            addFormRule: {
                title: [
                    {required: true, message: '请输入消息标题', trigger: 'blur'}
                ],
                content: [
                    {required: true, message: '请输入消息内容', trigger: 'blur'}
                ],
                departmentIds: [
                    {required: true, message: '请选择接收人', trigger: 'change', type: 'array'}
                ]
            },
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['addForm'].validate((valid) => {
                if (valid) {
                    this.loading = true;
                    reqAddMessage(this.addForm).then((res) => {
                        this.loading = false;
                        if (res.data.code === 1) {
                            this.$Message.success('添加成功');
                            this.$emit('getMessageList');
                            this.closeModal(false);
                        }
                    }).catch(() => {
                        this.loading = false;
                        this.$Message.error('添加失败');
                    });
                }
            });
        },
        getOption() {
            reqGetDepartmentOrgOpts().then((res)=>{
                this.departmentOpts = res.data.data;
            }).catch(()=>{
                this.departmentOpts = []
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
                this.getOption()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
