<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>系统管理</BreadcrumbItem>
                <BreadcrumbItem>消息管理</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="filter.keywords" placeholder="输入消息标题、内容" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="handleAdd" v-if="permissionButton.add">新增</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table stripe :columns="columns" :data="dataList" :loading="loading">
                    <template slot-scope="{ row, index }" slot="action">
                        <a v-if="permissionButton.edit && row.status === '草稿'" @click="handleEdit(row, index)">编辑</a>
                        <Divider v-if="permissionButton.edit && row.status === '草稿'" type="vertical"/>
                        <a v-if="permissionButton.publish && row.status === '草稿'"
                           @click="handlePublish(row, index)">发布</a>
                        <Divider v-if="permissionButton.publish && row.status === '草稿'" type="vertical"/>
                        <a v-if="permissionButton.view" @click="handleView(row, index)">详情</a>
                        <Divider type="vertical"/>
                        <a v-if="permissionButton.del" @click="handleDel(row, index)">删除</a>
                    </template>
                </Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
        <add-message v-model="showAddModal" v-on:getMessageList="getMessageList"></add-message>
        <edit-message v-model="showEditModal" :id="id" v-on:getMessageList="getMessageList"></edit-message>
        <view-message v-model="showViewModal" :id="id" v-on:getMessageList="getMessageList"></view-message>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {reqDeleteMessage, reqMessagePage, reqPublish} from "@/api/admin-api";
import viewMessage from './view'
import addMessage from './add'
import editMessage from './edit'

export default {
    name: 'resumeList',
    components: {
        addMessage,
        editMessage,
        viewMessage
    },
    data() {
        return {
            id: '',
            showViewModal: false,
            showEditModal: false,
            showAddModal: false,
            userList: [],
            filter: {
                keywords: '',
            },
            loading: false,
            dataList: [],
            permissionButton: {
                add: false,
                edit: false,
                del: false,
                publish: false,
                view: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '序号',
                    type: 'index',
                    width: 100,
                    align: 'center',
                },
                {
                    title: '标题',
                    key: 'title',
                    minwidth: 200,
                },
                {
                    title: '内容',
                    key: 'content',
                    ellipsis: true,
                    minwidth: 500,
                },
                {
                    title: '状态',
                    key: 'status',
                    width: 100,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 200,
                    align: 'center',
                },
            ],
        }
    },
    methods: {
        getMessageList() {
            this.dataList = [];
            this.loading = true;
            let para = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.filter.keywords,
            };
            reqMessagePage(para).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        handlePublish(row, index) {
            let params = {
                status: '发布',
                id: row.id
            }
            this.$Modal.confirm({
                title: '发布通知',
                content: '是否发布通知',
                width: '320',
                onOk: () => {
                    reqPublish(params).then((res)=>{
                        if (res.data.code === 1) {
                            this.getMessageList();
                            this.$Message.success('发布成功');
                        }else {
                            this.$Message.success('发布失败');
                        }
                    })
                }
            })
        },
        handleAdd() {
            this.showAddModal = true;
        },
        handleEdit(row, index) {
            this.id = row.id
            this.showEditModal = true;
        },
        handleView(row, index) {
            this.id = row.id
            this.showViewModal = true;
        },
        handleDel(row, index) {
            this.$Modal.confirm({
                title: '删除通知',
                content: '是否删除通知',
                width: '240',
                onOk: () => {
                    reqDeleteMessage({id: row.id}).then((res) => {
                        if (res.data.code === 1) {
                            this.getMessageList();
                            this.$Message.success('删除成功');
                        } else {
                            this.$Message.error('删除失败');
                        }
                    })
                },
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getMessageList();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getMessageList();
        },
        doSearching() {
            this.pageNum = 1;
            this.getMessageList();
        },
    },
    mounted() {
        permission.getPermissionButton(this.$route.query.permissionId, this.permissionButton)
        this.getMessageList();
    }
}
</script>
