<template>
    <Modal v-model="showForm" title="查看消息" :mask-closable="false">
        <Form class="plain-form" ref="viewForm" :model="viewForm" label-colon :label-width="90">
            <FormItem label="接收人">{{ viewForm.targetName }}</FormItem>
            <FormItem label="消息标题">{{ viewForm.title }}</FormItem>
            <FormItem label="消息内容">{{ viewForm.content }}</FormItem>
            <FormItem label="消息状态">{{ viewForm.status }}</FormItem>
            <FormItem label="发送人">{{ viewForm.senderName }}</FormItem>
            <FormItem label="发送时间">{{ viewForm.sendTime }}</FormItem>
        </Form>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {reqGetMessageById} from "@/api/admin-api";

export default {
    name: "viewMessage",
    components: {},
    props: {
        value: {
            type: Boolean,
            default: false
        },
        id: '',
    },
    data() {
        return {
            loading: false,
            showForm: this.value,
            viewForm: {
                title: '',
                content: '',
                status: '',
                recipient: [],
                recipientName: '',
                senderName: '',
                sendTime: '',
            },
        }
    },
    methods: {
        getMessageById() {
            reqGetMessageById({id: this.id}).then((res) => {
                this.viewForm = res.data.data;
            }).catch(() => {
                this.viewForm = {}
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['viewForm'].resetFields();
                this.getMessageById()
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
